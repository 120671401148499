import { FunctionComponent } from "react";
import styles from "./FrameComponent.module.css";

const FrameComponent: FunctionComponent = () => {
  return (
    <div className={styles.navigationBarParent}>
      <div className={styles.navigationBar}>
        <div className={styles.navigationInnerBar}>
          <button className={styles.fashionLogo}>
            <img
              className={styles.untitledArtwork11Traced}
              alt=""
              src="../untitledartwork-1-1-traced-1-traced.svg"
            />
            <button className={styles.boulter}>BOULTER</button>
          </button>
          <div className={styles.menuRight}>
            <div className={styles.linksContainer}>
              <div className={styles.links}>
                <button className={styles.catalogue}>CATALOGUE</button>
                <button className={styles.fashion}>FASHION</button>
                <button className={styles.favourite}>FAVOURITE</button>
                <button className={styles.lifestyle}>LIFESTYLE</button>
              </div>
              <button className={styles.component2}>
                <div className={styles.component2Child} />
                <div className={styles.component2Item} />
                <div className={styles.component2Inner} />
                <div className={styles.lineDiv} />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.hero}>
        <div className={styles.heroSection}>
          <div className={styles.herocta}>
            <div className={styles.heroctaChild} />
            <div className={styles.heroctaItem} />
            <div className={styles.letsExploreUniqueClothes}>
              <p className={styles.lets}>LET’S</p>
              <p className={styles.lets}>EXPLORE</p>
              <p className={styles.lets}>UNIQUE</p>
              <p className={styles.clothes}>CLOTHES.</p>
            </div>
            <div className={styles.liveForInfluentialAndInnov}>
              Live for Influential and Innovative fashion!
            </div>
          </div>
          <img
            className={styles.womanImageIcon}
            alt=""
            src="../image-1@2x.png"
          />
        </div>
      </div>
      <img className={styles.brandsIcon} alt="" src="../brands@2x.png" />
      <div className={styles.newarrivalssection}>
        <div className={styles.newArrivals}>
          <div className={styles.title}>
            <img className={styles.leafIcon} alt="" src="../leaf.svg" />
            <div className={styles.title1}>Boulter Pant</div>
          </div>
          <div className={styles.fashioncards}>
            <div className={styles.fashioncard}>
              <img
                className={styles.fashionimageIcon}
                alt=""
                src="../fashionimage@2x.png"
              />
              <div className={styles.navigationInnerBar}>
                <div className={styles.text}>
                  <div className={styles.name}>READY</div>
                  <div className={styles.toFaceDifficulty}>
                    To Face Difficulty
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.fashioncard1}>
              <img
                className={styles.fashionimageIcon}
                alt=""
                src="../fashionimage1@2x.png"
              />
              <div className={styles.navigationInnerBar}>
                <div className={styles.text}>
                  <div className={styles.name1}>SET</div>
                  <div className={styles.forTheStyle}>For The Style</div>
                </div>
              </div>
            </div>
            <div className={styles.fashioncard2}>
              <img
                className={styles.fashionimageIcon}
                alt=""
                src="../fashionimage2@2x.png"
              />
              <div className={styles.navigationInnerBar}>
                <div className={styles.text}>
                  <div className={styles.name2}>GO</div>
                  <div className={styles.toExplore}>To Explore!</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.favourites}>
        <div className={styles.youngsFavourite}>
          <div className={styles.title2}>
            <img className={styles.titleChild} alt="" src="../vector-9.svg" />
            <div className={styles.wideRangeOfCollection}>
              Young’s Favourite
            </div>
          </div>
          <div className={styles.panelcontainer}>
            <div className={styles.favcard}>
              <img
                className={styles.favcardChild}
                alt=""
                src="../rectangle-50@2x.png"
              />
              <div className={styles.navigationInnerBar}>
                <div className={styles.text}>
                  <div className={styles.trendingOnInstagram}>
                    Trending on instagram
                  </div>
                  <div className={styles.exploreNow}>Explore Now!</div>
                </div>
              </div>
            </div>
            <div className={styles.favcard}>
              <img
                className={styles.favcardChild}
                alt=""
                src="../rectangle-49@2x.png"
              />
              <div className={styles.navigationInnerBar}>
                <div className={styles.text}>
                  <div className={styles.allNewCargoCottonPant}>
                    All New Cargo Cotton Pant
                  </div>
                  <div className={styles.exploreNow1}>Explore Now!</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.favourites1}>
        <div className={styles.youngsFavourite}>
          <div className={styles.title3}>
            <div className={styles.wideRangeOfCollection}>
              Wide Range Of Collection
            </div>
          </div>
          <div className={styles.panelcontainer1}>
            <div className={styles.favcard}>
              <img
                className={styles.favcardChild}
                alt=""
                src="../rectangle-501@2x.png"
              />
              <div className={styles.navigationInnerBar}>
                <div className={styles.text}>
                  <div className={styles.trendingOnInstagram}>
                    The Classic Shirts
                  </div>
                  <div className={styles.exploreNow}>Explore Now!</div>
                </div>
              </div>
            </div>
            <div className={styles.favcard3}>
              <img
                className={styles.favcardChild}
                alt=""
                src="../rectangle-491@2x.png"
              />
              <div className={styles.navigationInnerBar}>
                <div className={styles.text6}>
                  <div className={styles.ankleFitCottonPant}>
                    Ankle Fit Cotton Pant
                  </div>
                  <div className={styles.exploreNow1}>Explore Now!</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.appDownload}>
        <div className={styles.vouchers}>
          <div className={styles.downloadapp}>
            <div className={styles.comeVistUsNowParent}>
              <div
                className={styles.comeVistUsNow}
              >{`COME & VIST US NOW!`}</div>
              <div className={styles.balajiLayoutHongasandraBe}>
                Balaji Layout, Hongasandra, Bengaluru, Karnataka 560068
              </div>
            </div>
            <div className={styles.downloadappInner}>
              <button className={styles.rectangleWrapper}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="../rectangle-55@2x.png"
                />
              </button>
            </div>
          </div>
        </div>
        <img className={styles.image2Icon} alt="" src="../image-2@2x.png" />
      </div>
      <div className={styles.trainers}>
        <div className={styles.trainerscta}>
          <div className={styles.ctaframe}>
            <div className={styles.background} />
            <div className={styles.title4}>
              <img
                className={styles.whatsappImage20221224At1}
                alt=""
                src="../whatsapp-image-20221224-at-1415@2x.png"
              />
            </div>
            <div className={styles.description}>
              <div className={styles.growingUpIAlwaysDreamedO}>
                <p className={styles.lets}>
                  Growing up, I always dreamed of becoming a police officer. I
                  worked hard to earn good grades and prepare for the exams, but
                  unfortunately, I wasn't accepted into the police academy.
                </p>
                <p className={styles.lets}>
                  Feeling disappointed but determined to succeed, I took a job
                  as a supervisor at a small clothing company. While it wasn't
                  my dream job, I was grateful for the opportunity and worked
                  hard to learn as much as I could about the business.
                </p>
                <p className={styles.clothes}>
                  Over the years, I gained valuable experience and developed a
                  passion for the clothing industry. I eventually decided to
                  take a risk and start my own clothing brand. Today, I am the
                  proud owner of three successful clothing brands and am
                  grateful for the journey that brought me here.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.trainersimages}>
          <div className={styles.desktop}>
            <div className={styles.trainer3}>
              <img
                className={styles.trainer3Child}
                alt=""
                src="../group-142@2x.png"
              />
              <div className={styles.coys}>COYS</div>
            </div>
            <div className={styles.trainer2}>
              <img
                className={styles.trainer3Child}
                alt=""
                src="../group-147@2x.png"
              />
              <div className={styles.coys}>WINSPRIT</div>
            </div>
            <div className={styles.trainer1}>
              <img
                className={styles.trainer1Child}
                alt=""
                src="../group-146@2x.png"
              />
              <div className={styles.boulter1}>BOULTER</div>
            </div>
          </div>
          <div className={styles.tablet}>
            <div className={styles.trainer31}>
              <img
                className={styles.trainer3Item}
                alt=""
                src="../group-1471@2x.png"
              />
              <div className={styles.karenSummer}>Karen Summer</div>
              <div className={styles.trainer}>Trainer</div>
            </div>
            <div className={styles.trainer21}>
              <img
                className={styles.trainer2Item}
                alt=""
                src="../group-1461@2x.png"
              />
              <div className={styles.jonathanWise}>Jonathan Wise</div>
              <div className={styles.trainer4}>Trainer</div>
            </div>
            <div className={styles.trainer11}>
              <img
                className={styles.trainer1Item}
                alt=""
                src="../group-1421@2x.png"
              />
              <div className={styles.samanthaWilliam}>Samantha William</div>
              <div className={styles.trainer5}>Trainer</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.community}>
        <div className={styles.communityInner}>
          <div className={styles.join}>
            <div className={styles.joinOurclothingCommunityNow}>
              JOIN OUR CLOTHING COMMUNITY NOW !!!
            </div>
            <div className={styles.typeYourEmailDownBelowAnd}>
              Type your email down below and be young wild generation
            </div>
          </div>
          <div className={styles.form}>
            <input
              className={styles.addYourEmailHere}
              type="text"
              placeholder="Add your email here"
            />
            <button className={styles.button}>
              <div className={styles.send}>SEND</div>
            </button>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.innerfooter}>
          <div className={styles.fashion1}>
            <div className={styles.boulter2}>BOULTER</div>
            <div className={styles.completeYourStyleWithAweso}>
              Complete your style with awesome clothes from us.
            </div>
            <div className={styles.logos}>
              <div className={styles.rectangleParent}>
                <div className={styles.groupChild} />
                <img className={styles.vectorIcon} alt="" src="../vector.svg" />
              </div>
              <div className={styles.rectangleParent}>
                <div className={styles.groupChild} />
                <img
                  className={styles.vectorIcon1}
                  alt=""
                  src="../vector1.svg"
                />
              </div>
              <div className={styles.rectangleParent}>
                <div className={styles.groupChild} />
                <img
                  className={styles.vectorIcon2}
                  alt=""
                  src="../vector2.svg"
                />
              </div>
              <div className={styles.rectangleParent}>
                <div className={styles.groupChild} />
                <b className={styles.in}>in</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrameComponent;
